
  
  /* Modal Dialog */
  .modal-dialog {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .modal-content {
    border-radius: 8px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .modal-header {
    background-color: #ff704d;
    color: white;
    padding: 15px 20px;
    text-align: center;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* Modal Body */
  .modal-body {
    padding: 20px;
    background-color: #ffe6cc;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 8px 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    transition: all 0.3s;
  }
  
  .form-control:focus {
    border-color: #ff704d;
    box-shadow: 0 0 4px rgba(255, 112, 77, 0.6);
  }
  
  .form-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  /* Buttons */
  .ai-button {
    background-color: #ff704d;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    margin: 15px 0;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .ai-button:hover {
    background-color: #e65c39;
  }
  
  .cancel-button,
  .save-button {
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .cancel-button {
    background-color: #ddd;
    color: #555;
  }
  
  .cancel-button:hover {
    background-color: #bbb;
  }
  
  .save-button {
    background-color: #ff704d;
    color: white;
  }
  
  .save-button:hover {
    background-color: #e65c39;
  }
  
  /* Modal Footer */
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px 20px;
    background-color: #f5f5f5;
  }
  