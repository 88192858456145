/* Styling for the outer container */
.py-2 {
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content vertically */
  justify-content: flex-end; /* Center content horizontally */
}

.button-container {
  display: flex;
  width: 100%;
  align-items: center; /* Center vertically */
  justify-content: flex-end; /* Align items to the start (left) */
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  margin-top: 2%;
}
/* Styling for the button */
.button {
  background-color: #eb6b3c;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none; 
  border-radius: 8px;
}

.button:hover {
  background-color: #ff944d;
}

.line-container {
  display: flex;
  align-items: center; /* Center vertically */
}

.message {
  margin-right: 10px; /* Adjust the margin as needed */
}
/* Styling for the video container */
.VideoCapture {
  width: 100%;
  /* max-width: 400px; */
  /* height: auto; */
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  /* padding: 10px;
    margin: 20px 0; */
}

/* Styling for the question container */
.question-container {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding:5px 10px 10px 10px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

/* Styling for the completed interview section */
h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  font-size: 16px;
  /* margin-bottom: 20px; */
  /* border-bottom: 1px solid #e0e0e0; */
  padding-bottom: 10px;
}
