/* VideoCard.css */

.custom-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .video-wrapper {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 aspect ratio for responsive video */
  }
  
  .video-wrapper video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card-title {
    margin-top: 10px;
  }
  
  .text-muted {
    color: #777;
  }
  