.overview-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    font-family: "Roboto";
    color: #222225;
}

.result-box {
    height: 58px;
    width: 166px;
    border-radius: 6px;
}

.result-type {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.result-percentage {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #222225;
}

.blue-text {
    color: #1E3C7A;
}

.blue-bg {
    background-color: #DEE9FF;
}

.red-text {
    color: #E40007;
}

.red-bg {
    background-color: #FFF4F5;
}

.green-text {
    color: #01651C;
}

.green-bg {
    background-color: #F0FFF4;
}

.pruple-text {
    color: #3C21C9;
}

.purple-bg {
    background-color: #EFECFF;
}

.yellow-text {
    color: #754B00;
}

.yellow-bg {
    background-color: #FFF7EA;
}

.grey-text {
    color: black;
}

.grey-bg {
    background-color: #F5F5F6;
}

.tip-border {
    width: 8px;
    height: 60px;
}

.tip-heading {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    font-family: "Roboto ";
}