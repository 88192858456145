/* Styling for the VideoCapture component */
.Container {
  width: 80%;
  display: flex;
  justify-content: flex-end; /* Use space-between to align buttons to the right */
  padding: 10px; /* Add some padding for spacing */
}

/* Styling for the video frame */
.Webcam {
  width: 100%;
  max-width: 100%;
  height: 300px; /* Adjust the height as needed */
  object-fit: cover; /* Maintain the aspect ratio and cover the entire container */
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Styling for the buttons */
.button {
  background-color: #eb6b3c;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  outline: none; /* Remove the default button focus outline */
  border-radius: 8px;

}

button:hover {
  background-color: #ff944d;
}
