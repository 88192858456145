
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.video-assessment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #121212;
  color: #fff;
  /* flex-direction: row; */
  min-height: 100vh;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.video-section {
  display: flex;
  justify-content: center;
  width: 100%;
  /* max-width: 1200px; */
  /* margin-bottom: 20px; */
}

.responsive-video {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

.qa-section {
  width: 100%;
  /* max-width: 800px; */
  text-align: center;
}

.question-box,
.answer-box {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #1f1f1f;
}

.ai-icon img,
.user-icon-img {
  /* height: 50px; */
  width: 70px;
}

.controls-btn {
  background-color: #ff704d;
  color: #fff;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.controls-btn:hover {
  transform: scale(1.05);
  background-color: #e65a3c;
}

@media (max-width: 768px) {
  .video-section {
    flex-direction: column;
  }

  .responsive-video {
    width: 100%;
    max-width: 100%;
    height: auto;
  }

  .qa-section {
    padding: 0 10px;
  }

  .question-box,
  .answer-box {
    font-size: 14px;
  }

  .controls-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .responsive-video {
    height: auto;
    border-radius: 5px;
  }

  .controls-btn {
    padding: 6px 12px;
    font-size: 12px;
  }
}


.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip:hover::after {
  content: attr(data-tooltip); /* Tooltip text */
  position: absolute;
  bottom: 100%; /* Show above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 10;
  opacity: 1;
}

.current-question {
  /* margin-top: 20px; */
  /* width: 100%;
  max-width: 600px; */
  /* margin-bottom: 20px;
  padding: 20px; */
  border-radius: 12px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); */
  /* background: #2e2e2e; */
  /* background: linear-gradient(to left, #000000, #1e90ff); */
  /* background: #fff; */
}

.question-box,
.answer-box {
  /* width: 100%;
    max-width: 600px; */
    padding: 20px;
    /* margin-bottom: 20px; */
    /* background-color: #333; */
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    /* background: #2e2e2e; */
    /* background: linear-gradient(to left, #000000, #1e90ff); */
    /* border-radius: 10px; */
    /* display: flex; */
    align-items: center;
    position: relative;
}
.final-message {
  text-align: center;
  font-size: 20px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers both vertically and horizontally */
  align-items: center;
  /* Full viewport height for vertical centering */
}

.final-message h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #ccc;
}
.loading-text {
  margin-top: 20px;
}

.text-line {
  opacity: 0;
  font-size: 18px;
  animation: showText 1.5s forwards;
}

.text-line.one {
  animation-delay: 1s;
}

.text-line.two {
  animation-delay: 3s;
}

.text-line.three {
  animation-delay: 5s;
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.timer {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, border 0.3s ease;
}

.timer svg {
  margin-right: 5px;
}

.timer.warning {
  color: #ff4c4c;
  border: 2px solid #ff4c4c;
  padding: 5px;
  border-radius: 5px;
}

/* .answer-box {
  background-color: #1a1a1a;
} */

  
.ai-icon, .user-icon {
  font-size: 35px;
  margin-right: 10px;
}

.question-text, .answer-text {
  font-size: 15px;
}

.stop-recording-button,
.start-recording-button {
  background-color: #007bff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.stop-recording-button:hover,
.start-recording-button:hover {
  background-color: #0056b3;
}

.thank-you-message {
  text-align: center;
  font-size: 20px;
  padding: 20px;
}

.next-question-timer {
  font-size: 16px;
  color: #ccc;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.next-question-timer svg {
  margin-right: 5px;
}
.thank-you-message {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}
.flipping {
  height: 22.4px;
  display: grid;
  grid-template-columns: repeat(5, 22.4px);
  grid-gap: 5.6px; 

}

.flipping div {
  animation: flipping-owie1ymd 1.25s calc(var(--delay) * 1s) infinite ease;
  background-color: #dd0d0d;
  
}

.flipping div:nth-of-type(1) {
  --delay: 0.25;
}

.flipping div:nth-of-type(2) {
  --delay: 0.5;
}

.flipping div:nth-of-type(3) {
  --delay: 0.75;
}

.flipping div:nth-of-type(4) {
  --delay: 1;
}

.flipping div:nth-of-type(5) {
  --delay: 1.25;
}

@keyframes flipping-owie1ymd {
  0% {
     transform: perspective(44.8px) rotateY(-180deg);
  }

  50% {
     transform: perspective(44.8px) rotateY(0deg);
  }
}
/* Responsive Design */
@media (min-width: 768px) {
   .qa-section {
    flex: 1;
  /* background: linear-gradient(to top, #000, #f87351
  );  */

  }
  .stop-recording-button,
  .start-recording-button {
    font-size: 18px;
    padding: 15px 30px;
  }

  .thank-you-message {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .question-text, .answer-text {
    font-size: 15px;
  }

  .ai-icon {
    font-size: 40px;
  }
  .user-icon {
    font-size: 20px;
  }
  .timer {
    font-size: 20px;
  }

  .next-question-timer {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .question-text, .answer-text {
    font-size: 15px;
  }

  .ai-icon{
    font-size: 40px;
  }
  .user-icon {
    font-size: 20px;
  }


  .timer {
    font-size: 22px;
  }

  .next-question-timer {
    font-size: 20px;
  }

  .stop-recording-button,
  .start-recording-button {
    font-size: 20px;
    padding: 20px 40px;
  }

  .thank-you-message {
    font-size: 24px;
  }
}
