@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

/* Container Styling */
.candidate-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

/* Tabs Styling */
.candidate-tabs {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
}

.candidate-tab-btn {
  flex: 1;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px 8px 0 0;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.candidate-tab-btn:not(:last-child) {
  margin-right: 10px;
}

.candidate-tab-btn:hover {
  background-color: #e0e0e0;
}

.candidate-active-tab {
  background-color: #007bff;
  color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 123, 255, 0.5);
  border-bottom: 2px solid #007bff;
}

/* Variables converted to values */
.header-height {
  height: 70px;
}

.menu-width {
  width: 264px;
}

.menu-collapsed-width {
  width: 80px;
}

/* Colors */
.theme-color {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.theme-color2 {
  background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}

.primary-color {
  color: #04a9f5;
}

.warning-color {
  color: #f4c22b;
}

.danger-color {
  color: #f44236;
}

.success-color {
  color: #1de9b6;
}

.purple-color {
  color: #a389d4;
}

.info-color {
  color: #17a2b8;
}

.secondary-color {
  color: #748892;
}

.default-color {
  color: #d6d6d6;
}

.light-color {
  color: #f2f2f2;
}

.dark-color {
  color: #37474f;
}

.inverse-color {
  color: #37474f;
}

.theme-border {
  border-color: #eaeaea;
}

/* Header */
.brand-background {
  background-color: #3f4d67;
}

.header-dark-background {
  background-color: #3f4d67;
  color: #fff;
}

.header-light-background {
  background-color: #fff;
  color: #3f4d67;
}

/* Menu */
.menu-caption-color {
  color: #14171d;
}

.menu-dark-background {
  background-color: #3f4d67;
  color: #a9b7d0;
}

.menu-light-background {
  background-color: #fff;
  color: #3f4d67;
}

.menu-active-color {
  color: #04a9f5;
}

/* Dark Layout Colors */
.dark-layout {
  background-color: #212224;
  color: #adb7be;
}

.menu-icon-color {
  color: #04a9f5, #ff5252, #01a9ac, #9575cd, #23b7e5, #f4c22b;
}

.color-header-name {
  color: blue, red, purple, lightblue, dark;
}

.color-header-color {
  color: #04a9f5, #ff5252, #9575cd, #23b7e5, lighten(#212224, 7%);
}

.color-menu-name {
  color: blue, red, purple, lightblue, dark;
}

.color-menu-color {
  color: #04a9f5, #ff5252, #9575cd, #23b7e5, lighten(#212224, 7%);
}

.color-active-name {
  color: blue, red, purple, lightblue, dark;
}

.color-active-color {
  color: #04a9f5, #ff5252, #9575cd, #23b7e5, lighten(#212224, 7%);
}

.color-title-name {
  color: blue, red, purple, lightblue, dark;
}

.color-title-color {
  color: #04a9f5, #ff5252, #9575cd, #23b7e5, lighten(#212224, 7%);
}

.layout-6-bg-color {
  background-color: #23b7e5;
}

.theme-font-family {
  font-family: 'Open Sans', sans-serif;
}

.theme-font-size {
  font-size: 14px;
}

.theme-background {
  background-color: #f4f7fa;
}

.theme-font-color {
  color: #888;
}

.theme-heading-color {
  color: #111;
}

.color-name {
  color: #04a9f5, #f44236, #1de9b6, #f4c22b, #a389d4;
}

.color-bt-name {
  color: #04a9f5, #f44236, #1de9b6, #f4c22b, #17a2b8;
}

.form-bg {
  background-color: #f0f3f6;
}

.theme-bg {
  background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.theme-bg2 {
  background: linear-gradient(-135deg, #899fd4 0%, #a389d4 100%);
}

.card {
  border-radius: 0;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
  border: none;
  margin-bottom: 30px;
  --bs-card-title-color: #111;
}

.card .card-header {
  background-color: transparent;
  border-bottom: 1px solid #f1f1f1;
  padding: 20px 25px;
  position: relative;
}

.card .card-header h5 {
  margin-bottom: 0;
  color: #000;
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
  margin-right: 10px;
  line-height: 1.1;
  position: relative;
}

.card .card-header h5:after {
  content: '';
  background-color: #04a9f5;
  position: absolute;
  left: -25px;
  top: 0;
  width: 4px;
  height: 20px;
}

.card .card-footer {
  border-top: 1px solid #f1f1f1;
  background: transparent;
  padding: 25px;
}

.card .card-block,
.card .card-body {
  padding: 30px 25px;
}

.card.full-card {
  z-index: 99999;
  border-radius: 0;
}

/* Animations */
.anim-rotate {
  animation: anim-rotate 1s linear infinite;
}

@keyframes anim-rotate {
  100% {
    transform: rotate(360deg);
  }
}

.anim-close-card {
  animation: anim-close-card 1.4s linear;
}

@keyframes anim-close-card {
  100% {
    opacity: 0.3;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
/** =====================
      Data Tables css start
========================== **/

.table {
  color: #888;
}

.table td,
.table th {
  border-top: 1px solid #eaeaea;
  white-space: nowrap;
  padding: 1.05rem 0.75rem;
}

.table thead th {
  border-bottom: 1px solid #eaeaea;
}

.table tbody + tbody {
  border-top: 2px solid #eaeaea;
}

.table.table-sm th,
.table.table-sm td {
  padding: 0.35rem;
}

/* Border versions */
.table-bordered {
  border: 1px solid #eaeaea;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #eaeaea;
}

/* Zebra-striping */
.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: rgba(4, 169, 245, 0.05); /* transparentized #04a9f5 */
}

/* Hover effect */
.table-hover tbody tr:hover {
  background-color: rgba(4, 169, 245, 0.05); /* transparentized #04a9f5 */
}

/**====== Data Tables css end ======**/
/** =====================
      Form Component CSS start
========================== **/

.custom-select,
.form-control {
  background: #f8f9fa;
  padding: 10px 20px;
  font-size: 14px;
  height: auto;
}

.form-control.form-control-lg {
  font-size: 1.25rem;
}

.form-control.form-control-sm {
  height: calc(1.8125rem + 2px);
  font-size: 0.875rem;
}

.form-control.form-control-range {
  box-shadow: none;
}

.form-control:focus {
  background: #f8f9fa;
}

.form-control::-moz-placeholder {
  color: #aaaeb3;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #aaaeb3;
}

.form-control::-webkit-input-placeholder {
  color: #aaaeb3;
}

/* input group start */

.input-group {
  background: #f8f9fa;
}

.input-group .input-group-text {
  padding-left: 15px;
  padding-right: 15px;
  background: transparent;
}

.input-group .input-group-text i {
  font-size: 20px;
}

.input-group .custom-file-label {
  margin-bottom: 0;
}

.input-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}

.cust-file-button .custom-file-label {
  height: calc(2.25rem + 8px);
  line-height: 2.5;
}

.cust-file-button .custom-file-label::after {
  padding: 0.775rem 0.75rem;
  height: 2.65rem;
}

.custom-select {
  height: calc(2.25rem + 9px);
}

/* input group End */

select.form-control:not([size]):not([multiple]) {
  height: calc(2.55rem + 2px);
}

.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > select.form-control:not([size]):not([multiple]),
select.form-control-lg:not([size]):not([multiple]) {
  height: calc(3.375rem + 2px);
}

.minicolors .form-control {
  padding: 6px 12px 5px 44px;
}

/* form-select */

.select2-container {
  width: 100% !important;
}

.tt-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  cursor: pointer;
}

.bootstrap-tagsinput {
  width: 100%;
}

.custom-file-label {
  height: auto;
}

.custom-file-label::after {
  height: auto;
  padding: 0.55rem 0.75rem;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.was-validated .form-control:valid,
.form-control.is-valid {
  background-position: calc(100% - 10px) calc(0.375em + 0.4875rem);
}

.was-validated .form-control:invalid ~ .invalid-feedback {
  background: #fff;
  margin-top: 0;
  padding-top: 0.25em;
}

/**====== Form Component CSS end ======**/
