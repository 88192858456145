/**
* Template Name: iLanding
* Template URL: https://bootstrapmade.com/ilanding-bootstrap-landing-page-template/
* Updated: Oct 28 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
    --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Nunito",  sans-serif;
    --nav-font: "Inter",  sans-serif;
  }
  
  /* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
  :root { 
    --background-color: #ffffff; /* Background color for the entire website, including individual sections */
    --default-color: #212529; /* Default color used for the majority of the text content across the entire website */
    --heading-color: #2d465e; /* Color for headings, subheadings and title throughout the website */
    --accent-color: #0d83fd; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
  }
  
  /* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
  :root {
    --nav-color: #212529;  /* The default color of the main navmenu links */
    --nav-hover-color: #0d83fd; /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #0d83fd; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
  }
  
  /* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */
  
  .light-background {
    --background-color: #f3f9ff;
    --surface-color: #ffffff;
  }
  
  .dark-background {
    --background-color: #0d83fd;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #409dfd;
    --contrast-color: #ffffff;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
  }
  
  a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /* PHP Email Form Messages
  ------------------------------*/
  .php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
  }
  
  .php-email-form .loading {
    display: none;
    background: var(--surface-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
  }
  
  .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--surface-color);
    animation: php-email-form-loading 1s linear infinite;
  }
  
  @keyframes php-email-form-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    --background-color: rgba(255, 255, 255, 0);
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 20px 0;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .header-container {
    background: var(--surface-color);
    border-radius: 50px;
    padding: 5px 25px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  
  .scrolled .header .header-container {
    background: color-mix(in srgb, var(--surface-color), transparent 5%);
  }
  
  .header .logo {
    line-height: 1;
    padding-left: 5px;
  }
  
  .header .logo img {
    max-height: 36px;
    margin-right: 8px;
  }
  
  .header .logo h1 {
    font-size: 24px;
    margin: 0;
    font-weight: 500;
    color: var(--heading-color);
  }
  
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-size: 14px;
    padding: 8px 20px;
    margin: 0 0 0 30px;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .header .btn-getstarted:hover,
  .header .btn-getstarted:focus:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  @media (max-width: 1200px) {
    .header {
      padding-top: 10px;
    }
  
    .header .header-container {
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px 5px 10px 15px;
    }
  
    .header .logo {
      order: 1;
    }
  
    .header .btn-getstarted {
      order: 2;
      margin: 0 10px 0 0;
      padding: 6px 15px;
    }
  
    .header .navmenu {
      order: 3;
    }
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Navmenu - Desktop */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      padding: 18px 15px;
      font-size: 16px;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:last-child a {
      padding-right: 0;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color);
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Navmenu - Mobile */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      list-style: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-mobile-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-dropdown-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    position: relative;
  }
  
  .footer .footer-top {
    padding-top: 50px;
  }
  
  .footer .footer-about .logo {
    line-height: 1;
    margin-bottom: 25px;
  }
  
  .footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
  }
  
  .footer .footer-about .logo span {
    color: var(--heading-color);
    font-family: var(--heading-font);
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
  }
  
  .footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
  }
  
  .footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin-right: 10px;
    transition: 0.3s;
  }
  
  .footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
  }
  
  .footer .footer-links {
    margin-bottom: 30px;
  }
  
  .footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer .footer-links ul i {
    padding-right: 2px;
    font-size: 12px;
    line-height: 0;
  }
  
  .footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  .footer .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  .footer .footer-links ul a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: inline-block;
    line-height: 1;
  }
  
  .footer .footer-links ul a:hover {
    color: var(--accent-color);
  }
  
  .footer .footer-contact p {
    margin-bottom: 5px;
  }
  
  .footer .copyright {
    padding: 25px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .copyright p {
    margin-bottom: 0;
  }
  
  .footer .credits {
    margin-top: 8px;
    font-size: 13px;
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: -15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 44px;
    height: 44px;
    border-radius: 50px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
    bottom: 15px;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 170px 0 80px 0;
    text-align: center;
    position: relative;
  }
  
  .page-title h1 {
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .page-title .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
  }
  
  .page-title .breadcrumbs ol li+li {
    padding-left: 10px;
  }
  
  .page-title .breadcrumbs ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 90px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 66px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .section-title h2:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
  .hero {
    position: relative;
    padding-top: 160px;
    background: linear-gradient(135deg, color-mix(in srgb, var(--accent-color), transparent 95%) 50%, color-mix(in srgb, var(--accent-color), transparent 98%) 25%, transparent 50%);
  }
  
  .hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 90% 10%, color-mix(in srgb, var(--accent-color), transparent 92%), transparent 40%);
    pointer-events: none;
  }
  
  .hero .hero-content {
    position: relative;
    z-index: 1;
  }
  
  .hero .hero-content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
  }
  
  .hero .hero-content h1 .accent-text {
    color: var(--accent-color);
  }
  
  @media (max-width: 992px) {
    .hero .hero-content {
      text-align: center;
      margin-bottom: 3rem;
    }
  
    .hero .hero-content h1 {
      font-size: 2.5rem;
    }
  
    .hero .hero-content .hero-buttons {
      justify-content: center;
    }
  }
  
  @media (max-width: 575px) {
    .hero .hero-content h1 {
      font-size: 2rem;
    }
  }
  
  .hero .company-badge {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: color-mix(in srgb, var(--accent-color), transparent 92%);
    border-radius: 50px;
    color: var(--accent-color);
    font-weight: 500;
  }
  
  .hero .company-badge i {
    font-size: 1.25rem;
  }
  
  .hero .btn-primary {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 0.75rem 2.5rem;
    border-radius: 50px;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .hero .btn-primary:hover {
    background-color: color-mix(in srgb, var(--accent-color), black 20%);
    border-color: color-mix(in srgb, var(--accent-color), black 20%);
  }
  
  .hero .btn-link {
    color: var(--heading-color);
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .hero .btn-link:hover {
    color: var(--accent-color);
  }
  
  .hero .btn-link i {
    font-size: 1.5rem;
    vertical-align: middle;
  }
  
  .hero .hero-image {
    position: relative;
    text-align: center;
    z-index: 1;
  }
  
  .hero .hero-image img {
    max-width: 100%;
    height: auto;
  }
  
  .hero .customers-badge {
    position: absolute;
    bottom: 10px;
    right: 30px;
    background-color: var(--surface-color);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    animation: float-badge 3s ease-in-out infinite;
    will-change: transform;
  }
  
  .hero .customers-badge .customer-avatars {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .hero .customers-badge .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid var(--surface-color);
    margin-left: -8px;
  }
  
  .hero .customers-badge .avatar:first-child {
    margin-left: 0;
  }
  
  .hero .customers-badge .avatar.more {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-weight: 600;
  }
  
  .hero .customers-badge p {
    font-size: 0.875rem;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  @media (max-width: 992px) {
    .hero .customers-badge {
      position: static;
      margin: 1rem auto;
      max-width: 250px;
    }
  }
  
  .hero .stats-row {
    position: relative;
    z-index: 1;
    margin-top: 5rem;
    background-color: var(--surface-color);
    border-radius: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    padding-bottom: 2rem;
  }
  
  .hero .stat-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
  }
  
  .hero .stat-item .stat-icon {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color-mix(in srgb, var(--accent-color), transparent 92%);
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .hero .stat-item .stat-icon i {
    font-size: 1.5rem;
    color: var(--accent-color);
  }
  
  .hero .stat-item:hover .stat-icon {
    background-color: var(--accent-color);
  }
  
  .hero .stat-item:hover .stat-icon i {
    color: var(--contrast-color);
  }
  
  .hero .stat-item .stat-content {
    flex-grow: 1;
  }
  
  .hero .stat-item .stat-content h4 {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
    font-weight: 600;
  }
  
  .hero .stat-item .stat-content p {
    font-size: 0.7rem;
    /* color: color-mix(in srgb, var(--default-color), transparent 40%); */
    margin: 0;
    font-weight: bold;
    color: #0d83fd;
  }
  
  @media (max-width: 575px) {
    .hero .stat-item {
      padding: 1.5rem;
    }
  }
  
  @keyframes float-badge {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .about-meta {
    color: var(--accent-color);
    font-weight: 600;
    margin-bottom: 1rem;
    display: inline-block;
  }
  
  .about .about-title {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    line-height: 1.2;
    font-weight: 700;
  }
  
  @media (max-width: 992px) {
    .about .about-title {
      font-size: 2rem;
    }
  }
  
  .about .about-description {
    margin-bottom: 2rem;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
  }
  
  .about .feature-list-wrapper {
    margin-bottom: 2rem;
  }
  
  .about .feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .about .feature-list li {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  
  .about .feature-list li i {
    color: var(--accent-color);
    font-size: 1.25rem;
  }
  
  .about .profile .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .about .profile .profile-name {
    font-size: 1.125rem;
    margin: 0;
  }
  
  .about .profile .profile-position {
    color: var(--accent-color);
    margin: 0;
    font-size: 0.875rem;
  }
  
  .about .contact-info {
    padding: 1rem 1.5rem;
    background-color: var(--surface-color);
    border-radius: 0.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.06);
  }
  
  .about .contact-info i {
    color: var(--accent-color);
    font-size: 1.5rem;
  }
  
  .about .contact-info .contact-label {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 0.875rem;
    margin: 0;
  }
  
  .about .contact-info .contact-number {
    font-weight: 600;
    margin: 0;
  }
  
  .about .image-wrapper {
    position: relative;
  }
  
  @media (max-width: 992px) {
    .about .image-wrapper {
      padding-left: 0;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }
  }
  
  @media (max-width: 992px) {
    .about .image-wrapper .images {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  
  @media (max-width: 992px) {
    .about .image-wrapper .main-image {
      margin-left: 0;
    }
  }
  
  .about .image-wrapper .small-image {
    position: absolute;
    top: 20%;
    left: -10%;
    width: 45%;
    border: 8px solid var(--surface-color);
  }
  
  @media (max-width: 992px) {
    .about .image-wrapper .small-image {
      position: static;
      width: 100%;
      margin: 0 auto;
      border: 0;
    }
  }
  
  .about .image-wrapper .experience-badge {
    position: absolute;
    bottom: 5%;
    right: 5%;
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-align: center;
    min-width: 200px;
    animation: experience-float 3s ease-in-out infinite;
  }
  
  @media (max-width: 992px) {
    .about .image-wrapper .experience-badge {
      position: static;
      width: fit-content;
      margin: 0 auto;
    }
  }
  
  .about .image-wrapper .experience-badge h3 {
    color: var(--contrast-color);
    font-size: 2.5rem;
    margin: 0;
    line-height: 0.5;
  }
  
  .about .image-wrapper .experience-badge h3 span {
    font-size: 1rem;
    display: inline-block;
    margin-left: 0.25rem;
  }
  
  .about .image-wrapper .experience-badge p {
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
  }
  
  @keyframes experience-float {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  /*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
  .features .nav-tabs {
    border: 0;
    background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 6px;
    width: auto;
  }
  
  .features .nav-item {
    margin: 0;
    padding: 0 5px 0 0;
  }
  
  .features .nav-item:last-child {
    padding-right: 0;
  }
  
  .features .nav-link {
    background-color: none;
    color: var(--heading-color);
    padding: 10px 30px;
    transition: 0.3s;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    border: 0;
    margin: 0;
  }
  
  @media (max-width: 468px) {
    .features .nav-link {
      padding: 8px 20px;
    }
  }
  
  .features .nav-link i {
    padding-right: 15px;
    font-size: 48px;
  }
  
  .features .nav-link h4 {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }
  
  .features .nav-link:hover {
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .features .nav-link:hover h4 {
    color: var(--accent-color);
  }
  
  .features .nav-link.active {
    background-color: var(--accent-color);
    border-color: var(--accent-color);
  }
  
  .features .nav-link.active h4 {
    color: var(--contrast-color);
  }
  
  .features .tab-content {
    margin-top: 30px;
  }
  
  .features .tab-pane h3 {
    color: var(--heading-color);
    font-weight: 700;
    font-size: 32px;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  
  .features .tab-pane h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 60px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    bottom: 0;
  }
  
  .features .tab-pane ul {
    list-style: none;
    padding: 0;
  }
  
  .features .tab-pane ul li {
    padding-top: 10px;
  }
  
  .features .tab-pane ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
  }
  
  .features .tab-pane p:last-child {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Features Cards Section
  --------------------------------------------------------------*/
  .features-cards {
    --default-color: #555;
    --heading-color: #333;
  }
  
  .features-cards .feature-box {
    height: 100%;
    padding: 40px 30px;
    border-radius: 10px;
  }
  
  .features-cards .feature-box i {
    font-size: 44px;
    display: inline-block;
    line-height: 0;
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .features-cards .feature-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 0 0 10px 0;
  }
  
  .features-cards .feature-box p {
    font-size: 15px;
    margin-bottom: 0;
  }
  
  .features-cards .feature-box.orange {
    background-color: #fff3e2;
  }
  
  .features-cards .feature-box.orange i {
    color: #edb86e;
  }
  
  .features-cards .feature-box.blue {
    background-color: #deedfd;
  }
  
  .features-cards .feature-box.blue i {
    color: #20a5f8;
  }
  
  .features-cards .feature-box.green {
    background-color: #d5f1e4;
  }
  
  .features-cards .feature-box.green i {
    color: #48c88a;
  }
  
  .features-cards .feature-box.red {
    background-color: #fdeded;
  }
  
  .features-cards .feature-box.red i {
    color: #f28484;
  }
  
  /*--------------------------------------------------------------
  # Features 2 Section
  --------------------------------------------------------------*/
  .features-2 .feature-item .feature-icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: color-mix(in srgb, var(--accent-color), transparent 92%);
  }
  
  .features-2 .feature-item .feature-icon i {
    font-size: 24px;
    color: var(--accent-color);
  }
  
  .features-2 .feature-item .feature-content h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .features-2 .feature-item .feature-content p {
    color: color-mix(in srgb, var(--default-color), transparent 25%);
    font-size: 15px;
    margin-bottom: 0;
  }
  
  .features-2 .phone-mockup {
    position: relative;
    padding: 20px 0px;
  }
  
  .features-2 .phone-mockup img {
    max-width: 500px;
    margin-left: -70px;
    height: auto;
    filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.1));
  }
  
  @media (max-width: 991.98px) {
    .features-2 .feature-item {
      text-align: center !important;
      margin-bottom: 2rem;
    }
  
    .features-2 .feature-item .d-flex {
      flex-direction: column;
      text-align: center;
      justify-content: center !important;
    }
  
    .features-2 .phone-mockup {
      margin: 3rem 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action .container {
    background: var(--accent-color);
    color: var(--contrast-color);
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding: 4rem 2rem;
  }
  
  .call-to-action h2,
  .call-to-action p {
    color: var(--contrast-color);
    position: relative;
    z-index: 2;
  }
  
  .call-to-action .btn-cta {
    background-color: color-mix(in srgb, var(--contrast-color) 15%, transparent);
    color: var(--contrast-color);
    padding: 12px 40px;
    border-radius: 50px;
    transition: all 0.3s ease;
    border: 2px solid var(--contrast-color);
    position: relative;
    z-index: 2;
  }
  
  .call-to-action .btn-cta:hover {
    background-color: var(--contrast-color);
    color: var(--accent-color);
  }
  
  .call-to-action .shape {
    position: absolute;
    pointer-events: none;
    z-index: 1;
  }
  
  .call-to-action .shape svg {
    width: 100%;
    height: 100%;
  }
  
  .call-to-action .shape svg path {
    fill: color-mix(in srgb, var(--contrast-color) 70%, transparent);
  }
  
  .call-to-action .shape-1 {
    width: 300px;
    height: 300px;
    top: -100px;
    right: -50px;
    opacity: 0.1;
    transform: rotate(45deg);
    animation: shapes-float 3s ease-in-out infinite;
  }
  
  .call-to-action .shape-2 {
    width: 200px;
    height: 200px;
    bottom: -50px;
    left: -50px;
    opacity: 0.15;
    transform: rotate(-15deg);
    animation: shapes-float 4s ease-in-out infinite;
  }
  
  .call-to-action .shape-3 {
    width: 150px;
    height: 150px;
    top: 20%;
    right: 15%;
    opacity: 0.08;
    transform: rotate(15deg);
  }
  
  .call-to-action .dots {
    position: absolute;
    pointer-events: none;
    z-index: 1;
    color: var(--contrast-color);
  }
  
  .call-to-action .dots svg {
    width: 100%;
    height: 100%;
  }
  
  .call-to-action .dots-1 {
    width: 200px;
    height: 200px;
    top: -30px;
    left: 10%;
    opacity: 0.1;
    transform: rotate(15deg);
    animation: shapes-float 4s ease-in-out infinite;
  }
  
  .call-to-action .dots-2 {
    width: 150px;
    height: 150px;
    bottom: 20px;
    right: 15%;
    opacity: 0.15;
    transform: rotate(-10deg);
  }
  
  @keyframes shapes-float {
  
    0%,
    100% {
      transform: scale(0.8) rotate(45deg) translateY(0);
    }
  
    50% {
      transform: scale(0.8) rotate(45deg) translateY(-20px);
    }
  }
  
  @media (max-width: 991.98px) {
    .call-to-action .container {
      padding: 3rem 1.5rem;
    }
  
    .call-to-action .shape-1 {
      width: 200px;
      height: 200px;
    }
  
    .call-to-action .shape-2 {
      width: 150px;
      height: 150px;
    }
  
    .call-to-action .shape-3 {
      width: 100px;
      height: 100px;
    }
  
    .call-to-action .dots-1 {
      width: 150px;
      height: 150px;
    }
  
    .call-to-action .dots-2 {
      width: 120px;
      height: 120px;
    }
  
    .call-to-action .dots-3 {
      width: 80px;
      height: 80px;
    }
  }
  
  /*--------------------------------------------------------------
  # Clients Section
  --------------------------------------------------------------*/
  .clients .swiper-slide img {
    opacity: 0.5;
    transition: 0.3s;
    filter: grayscale(100);
  }
  
  .clients .swiper-slide img:hover {
    filter: none;
    opacity: 1;
  }
  
  .clients .swiper-wrapper {
    height: auto;
  }
  
  .clients .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    background-color: color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .clients .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
  .testimonials .testimonial-item {
    background-color: var(--surface-color);
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.08);
    padding: 30px;
    position: relative;
    height: 100%;
  }
  
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    float: left;
    margin: 0 10px 0 0;
  }
  
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
  }
  
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    margin: 0;
  }
  
  .testimonials .testimonial-item .stars {
    margin: 10px 0;
  }
  
  .testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
  }
  
  .testimonials .testimonial-item .quote-icon-left,
  .testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
  .stats .stats-item {
    padding: 30px;
    width: 100%;
  }
  
  .stats .stats-item span {
    color: var(--heading-color);
    font-size: 48px;
    display: block;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .stats .stats-item span:after {
    content: "";
    position: absolute;
    display: block;
    width: 25px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  
  .stats .stats-item p {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 0;
    margin: 0;
    font-family: var(--heading-font);
    font-weight: 500;
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services .service-card {
    height: 100%;
    padding: 30px;
    background: var(--surface-color);
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  .services .service-card:hover {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
  }
  
  .services .service-card:hover .icon {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .services .service-card:hover .read-more {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
  }
  
  .services .service-card .icon {
    width: 60px;
    height: 60px;
    margin-right: 30px;
    background: color-mix(in srgb, var(--accent-color), transparent 90%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: var(--accent-color);
    font-size: 28px;
    transition: all 0.3s ease;
    line-height: 1;
  }
  
  .services .service-card h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  .services .service-card p {
    margin-bottom: 25px;
    color: color-mix(in srgb, var(--default-color), transparent 25%);
    font-size: 15px;
    line-height: 1.6;
  }
  
  .services .service-card .read-more {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: var(--accent-color);
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .services .service-card .read-more i {
    font-size: 14px;
    transition: transform 0.3s ease;
  }
  
  .services .service-card .read-more:hover i {
    transform: translateX(5px);
  }
  
  /*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
  .pricing .pricing-card {
    height: 100%;
    padding: 2rem;
    background: var(--surface-color);
    border-radius: 1rem;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .pricing .pricing-card:hover {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  }
  
  .pricing .pricing-card.popular {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card.popular h3,
  .pricing .pricing-card.popular h4 {
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card.popular .price .currency,
  .pricing .pricing-card.popular .price .amount,
  .pricing .pricing-card.popular .price .period {
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card.popular .features-list li {
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card.popular .features-list li i {
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card.popular .btn-light {
    background: var(--contrast-color);
    color: var(--accent-color);
  }
  
  .pricing .pricing-card.popular .btn-light:hover {
    background: color-mix(in srgb, var(--contrast-color), transparent 10%);
  }
  
  .pricing .pricing-card .popular-badge {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: var(--contrast-color);
    color: var(--accent-color);
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    font-size: 0.875rem;
    font-weight: 600;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.08);
  }
  
  .pricing .pricing-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .pricing .pricing-card .price {
    margin-bottom: 1.5rem;
  }
  
  .pricing .pricing-card .price .currency {
    font-size: 1.5rem;
    font-weight: 600;
    vertical-align: top;
    line-height: 1;
  }
  
  .pricing .pricing-card .price .amount {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 1;
  }
  
  .pricing .pricing-card .price .period {
    font-size: 1rem;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .pricing .pricing-card .description {
    margin-bottom: 2rem;
    font-size: 0.975rem;
  }
  
  .pricing .pricing-card h4 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }
  
  .pricing .pricing-card .features-list {
    list-style: none;
    padding: 0;
    margin: 0 0 2rem 0;
  }
  
  .pricing .pricing-card .features-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .pricing .pricing-card .features-list li i {
    color: var(--accent-color);
    margin-right: 0.75rem;
    font-size: 1.25rem;
  }
  
  .pricing .pricing-card .btn {
    width: 100%;
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    font-weight: 500;
    border-radius: 50px;
  }
  
  .pricing .pricing-card .btn.btn-primary {
    background: var(--accent-color);
    border: none;
    color: var(--contrast-color);
  }
  
  .pricing .pricing-card .btn.btn-primary:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
  }
  
  /*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
  .faq .faq-title {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: var(--heading-color);
  }
  
  .faq .faq-description {
    font-size: 1rem;
    color: var(--default-color);
    margin-bottom: 2rem;
  }
  
  .faq .faq-arrow {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-item {
    background-color: var(--surface-color);
    position: relative;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .faq .faq-container .faq-item:last-child {
    margin-bottom: 0;
  }
  
  .faq .faq-container .faq-item h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 30px 0 0;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .faq .faq-container .faq-item h3 .num {
    color: var(--accent-color);
    padding-right: 5px;
  }
  
  .faq .faq-container .faq-item h3:hover {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  
  .faq .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
  }
  
  .faq .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
  }
  
  .faq .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-active h3 {
    color: var(--accent-color);
  }
  
  .faq .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
  }
  
  .faq .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Call To Action 2 Section
  --------------------------------------------------------------*/
  .call-to-action-2 {
    padding: 80px 0;
  }
  
  .call-to-action-2 .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action-2 h3 {
    font-size: 28px;
    font-weight: 700;
    color: var(--default-color);
  }
  
  .call-to-action-2 p {
    color: var(--default-color);
  }
  
  .call-to-action-2 .cta-btn {
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 40px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 10px;
    border: 2px solid color-mix(in srgb, var(--contrast-color), transparent 30%);
    color: var(--contrast-color);
  }
  
  .call-to-action-2 .cta-btn:hover {
    border-color: var(--contrast-color);
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact .info-box {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 3rem;
    border-radius: 1rem;
    height: 100%;
  }
  
  .contact .info-box h3 {
    color: var(--contrast-color);
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 767.98px) {
    .contact .info-box h3 {
      font-size: 1.75rem;
    }
  }
  
  .contact .info-box p {
    opacity: 0.8;
    margin-bottom: 2rem;
  }
  
  @media (max-width: 992px) {
    .contact .info-box {
      padding: 1.5rem;
    }
  }
  
  .contact .info-item {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .contact .info-item:last-child {
    margin-bottom: 0;
  }
  
  .contact .info-item .icon-box {
    width: 3.5rem;
    height: 3.5rem;
    background-color: color-mix(in srgb, var(--contrast-color), transparent 85%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    transition: 0.3s;
  }
  
  .contact .info-item .icon-box i {
    font-size: 1.5rem;
    color: var(--contrast-color);
  }
  
  .contact .info-item:hover .icon-box {
    background-color: color-mix(in srgb, var(--contrast-color), transparent 70%);
  }
  
  .contact .info-item .content h4 {
    color: var(--contrast-color);
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  
  .contact .info-item .content p {
    margin-bottom: 0.25rem;
    font-size: 0.95rem;
    color: var(--contrast-color);
  }
  
  .contact .info-item a {
    color: var(--contrast-color);
  }
  
  .contact .info-item .content p:last-child {
    margin-bottom: 0;
  }
  
  .contact .contact-form {
    background-color: var(--surface-color);
    padding: 3rem;
    border-radius: 1rem;
    height: 100%;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.05);
  }
  
  @media (max-width: 992px) {
    .contact .contact-form {
      padding: 1.5rem;
    }
  }
  
  .contact .contact-form h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .contact .contact-form h3 {
      font-size: 1.75rem;
    }
  }
  
  .contact .contact-form p {
    color: color-mix(in srgb, var(--default-color), transparent 25%);
    margin-bottom: 2rem;
  }
  
  .contact .contact-form .form-control,
  .contact .contact-form .form-select {
    padding: 0.875rem 1.25rem;
    border-color: color-mix(in srgb, var(--default-color), transparent 90%);
    border-radius: 0.5rem;
    background-color: color-mix(in srgb, var(--surface-color) 90%, white 5%);
    color: var(--default-color);
  }
  
  .contact .contact-form .form-control:focus,
  .contact .contact-form .form-select:focus {
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .contact .contact-form .form-control::placeholder,
  .contact .contact-form .form-select::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .contact .contact-form .btn {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 1rem 2rem;
    border-radius: 2rem;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: 0.3s;
  }
  
  .contact .contact-form .btn i {
    font-size: 1.25rem;
  }
  
  .contact .contact-form .btn:hover {
    background-color: color-mix(in srgb, var(--accent-color), var(--contrast-color) 20%);
  }
  
  /*--------------------------------------------------------------
  # Service Details Section
  --------------------------------------------------------------*/
  .service-details .service-box {
    background-color: var(--surface-color);
    padding: 20px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }
  
  .service-details .service-box+.service-box {
    margin-top: 30px;
  }
  
  .service-details .service-box h4 {
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid color-mix(in srgb, var(--default-color), transparent 92%);
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  
  .service-details .services-list {
    background-color: var(--surface-color);
  }
  
  .service-details .services-list a {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    background-color: color-mix(in srgb, var(--default-color), transparent 96%);
    display: flex;
    align-items: center;
    padding: 12px 15px;
    margin-top: 15px;
    transition: 0.3s;
  }
  
  .service-details .services-list a:first-child {
    margin-top: 0;
  }
  
  .service-details .services-list a i {
    font-size: 16px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .services-list a.active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .service-details .services-list a.active i {
    color: var(--contrast-color);
  }
  
  .service-details .services-list a:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a {
    color: var(--default-color);
    display: flex;
    align-items: center;
    padding: 10px 0;
    transition: 0.3s;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .service-details .download-catalog a:first-child {
    border-top: 0;
    padding-top: 0;
  }
  
  .service-details .download-catalog a:last-child {
    padding-bottom: 0;
  }
  
  .service-details .download-catalog a i {
    font-size: 24px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  .service-details .download-catalog a:hover {
    color: var(--accent-color);
  }
  
  .service-details .help-box {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    margin-top: 30px;
    padding: 30px 15px;
  }
  
  .service-details .help-box .help-icon {
    font-size: 48px;
  }
  
  .service-details .help-box h4,
  .service-details .help-box a {
    color: var(--contrast-color);
  }
  
  .service-details .services-img {
    margin-bottom: 20px;
  }
  
  .service-details h3 {
    font-size: 26px;
    font-weight: 700;
  }
  
  .service-details p {
    font-size: 15px;
  }
  
  .service-details ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .service-details ul li {
    padding: 5px 0;
    display: flex;
    align-items: center;
  }
  
  .service-details ul i {
    font-size: 20px;
    margin-right: 8px;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
  .starter-section {
    /* Add your styles here */
  }
  