@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");
.empty {
  /* min-height: 100vh; */
  /* background: linear-gradient(135deg, #2e2e2e, #1b1b1b); */
  background: linear-gradient(
    to bottom,
    #000000,
    #ff704d
  ); /* Black to Vibrant Blue */
  /* font-family: 'Playfair Display', serif; Apply the custom font */
  font-family: "Montserrat", sans-serif; /* Apply the custom font */
  color: #fff;
}
.progress {
  width: 129.6px;
  height: 21.6px;
  -webkit-mask: linear-gradient(90deg, #b50909 70%, #0000 0) left/20% 100%;
  background: linear-gradient(#b50909 0 0) left/0% 100% no-repeat #dbdcef;
  animation: progress-422c3u 2s infinite steps(6);
}

@keyframes progress-422c3u {
  100% {
    background-size: 120% 100%;
  }
}

.initialiser {
  width: 40px;
  top: 50%;
  z-index: 5;
  aspect-ratio: 1;
  --c: linear-gradient(#ff0000ad 0 0);
  --r1: radial-gradient(farthest-side at bottom, #ff0000ad 93%, #0000);
  --r2: radial-gradient(farthest-side at top, #ff0000ad 93%, #0000);
  background: var(--c), var(--r1), var(--r2), var(--c), var(--r1), var(--r2),
    var(--c), var(--r1), var(--r2);
  background-repeat: no-repeat;
  animation: l2 1s infinite alternate;
  /* margin-left: 75px; */
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%);  */
}
@keyframes l2 {
  0%,
  25% {
    background-size: 8px 0, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0,
      8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(50% - 2px), 0 calc(50% + 2px), 50% 50%,
      50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  50% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 0, 8px 4px, 8px 4px, 8px 0,
      8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
      50% calc(50% - 2px), 50% calc(50% + 2px), 100% 50%, 100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  75% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px,
      8px 0, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
      50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(50% - 2px),
      100% calc(50% + 2px);
  }
  95%,
  100% {
    background-size: 8px 100%, 8px 4px, 8px 4px, 8px 100%, 8px 4px, 8px 4px,
      8px 100%, 8px 4px, 8px 4px;
    background-position: 0 50%, 0 calc(0% - 2px), 0 calc(100% + 2px), 50% 50%,
      50% calc(0% - 2px), 50% calc(100% + 2px), 100% 50%, 100% calc(0% - 2px),
      100% calc(100% + 2px);
  }
}

.initialiser-container {
  /* Center the container */
  /* position: absolute; */
 
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* transform: translate(-50%, -50%); */
  background-color: #000000;
  text-align: center; /* Ensure text and initialiser are aligned center */
}

.initialiser-text {
  font-size: 25px;
  margin-bottom: 30px; /* Space between the text and the loader */
  color: #ffff; /* Text color (same as loader color for consistency) */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* .video-assessment-container {

  flex-direction: row;
  min-height: 100vh;

  font-family: "Montserrat", sans-serif; 

  color: #fff;
} */
.question-box, .answer-box {
  /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
}

.voice-loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #4c86f9;
  --second-color: #49a84c;
  --third-color: #f6bb02;
  --fourth-color: #f6bb02;
  --fifth-color: #2196f3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  /* height: 100px; */
}

.voice-loading span {
  width: 4px;
  height: 30px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
}

.voice-loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
}

.voice-loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
}

.voice-loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
}

.voice-loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
}

@keyframes scale {
  0%,
  40%,
  100% {
    transform: scaleY(0.05);
  }

  20% {
    transform: scaleY(1);
  }
}
.question-box:hover, .answer-box:hover {
  /* transform: scale(1.05); */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}


.tooltip {
  display: inline-block;
  position: relative;
}

.tooltip:hover::after {
  content: attr(data-tooltip); /* Tooltip text */
  position: absolute;
  bottom: 100%; /* Show above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  z-index: 10;
  opacity: 1;
}

.current-question {
  /* margin-top: 20px; */
  /* width: 100%;
  max-width: 600px; */
  /* margin-bottom: 20px; */
  /* padding: 20px; */
  border-radius: 12px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); */
  /* background: #2e2e2e; */
  /* background: linear-gradient(to left, #000000, #ff704d); */
  /* background: #fff; */
}

.question-box,
.answer-box {
  /* width: 100%;
  max-width: 600px; */
  padding: 20px;
  /* margin-bottom: 20px; */
  /* background-color: #333; */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  /* background: #2e2e2e; */
  background: linear-gradient(to left, #000000, #ff704d);
  /* border-radius: 10px; */
  /* display: flex; */
  align-items: center;
  position: relative;
}
.final-message {
  text-align: center;
  font-size: 20px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers both vertically and horizontally */
  align-items: center;
  /* Full viewport height for vertical centering */
}

.video-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  /* background: #fff; */
}

.video-section .webcam-container {
  width: 100%;
  max-width: 500px;
  border: 5px solid #333;
  border-radius: 10px;
  overflow: hidden;
}

.qa-section {
  flex: 1;
  display: flex;
  /* height: 35vh; */
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  /* padding: 20px; */
  /* border-radius: 12px; */
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6); */
  /* background: #2e2e2e; */
  /* background: linear-gradient(to left, #000000, #ff704d); */
}

.controls-btn button:disabled {
  /*  background: #d3d3d3; Gray out the button */
  color: #ffffff; /* Change the text color */
  cursor: not-allowed; /* Disable the cursor */
  opacity: 0.6; /* Add transparency to show it's inactive */
}

.final-message h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #ccc;
}
.loading-text {
  margin-top: 20px;
}

.text-line {
  opacity: 0;
  font-size: 18px;
  animation: showText 1.5s forwards;
}

.text-line.one {
  animation-delay: 1s;
}

.text-line.two {
  animation-delay: 3s;
}

.text-line.three {
  animation-delay: 5s;
}

@keyframes showText {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.timer {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease, border 0.3s ease;
}

.timer svg {
  margin-right: 5px;
}

.timer.warning {
  color: #ff4c4c;
  border: 2px solid #ff4c4c;
  padding: 5px;
  border-radius: 5px;
}

/* .answer-box {
  background-color: #1a1a1a;
} */

.ai-icon,
.user-icon {
  font-size: 35px;
  margin-right: 10px;
}

.question-text,
.answer-text {
  font-size: 15px;
}

.stop-recording-button,
.start-recording-button {
  background-color: #ff704d;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.stop-recording-button:hover,
.start-recording-button:hover {
  background-color: #ff704d;
}

.thank-you-message {
  text-align: center;
  font-size: 20px;
  padding: 20px;
}

.next-question-timer {
  font-size: 16px;
  color: #ccc;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.next-question-timer svg {
  margin-right: 5px;
}
.thank-you-message {
  margin-top: 20px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}
.flipping {
  height: 22.4px;
  display: grid;
  grid-template-columns: repeat(5, 22.4px);
  grid-gap: 5.6px;
}

.flipping div {
  animation: flipping-owie1ymd 1.25s calc(var(--delay) * 1s) infinite ease;
  background-color: #dd0d0d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flipping div:nth-of-type(1) {
  --delay: 0.25;
}

.flipping div:nth-of-type(2) {
  --delay: 0.5;
}

.flipping div:nth-of-type(3) {
  --delay: 0.75;
}

.flipping div:nth-of-type(4) {
  --delay: 1;
}

.flipping div:nth-of-type(5) {
  --delay: 1.25;
}

@keyframes flipping-owie1ymd {
  0% {
    transform: perspective(44.8px) rotateY(-180deg);
  }

  50% {
    transform: perspective(44.8px) rotateY(0deg);
  }
}
/* Responsive Design */
@media (min-width: 768px) {
  .video-section,
  .qa-section {
    flex: 1;
    /* background: linear-gradient(
      to bottom,
      #000000,
      #ff704d
    ); Black to Vibrant Blue */
  }
  .stop-recording-button,
  .start-recording-button {
    font-size: 18px;
    padding: 15px 30px;
  }

  .thank-you-message {
    font-size: 22px;
  }
}

@media (min-width: 1024px) {
  .question-text,
  .answer-text {
    font-size: 15px;
  }

  .ai-icon {
    font-size: 40px;
  }
  .user-icon {
    font-size: 20px;
  }
  .timer {
    font-size: 20px;
  }

  .next-question-timer {
    font-size: 18px;
  }
}

@media (min-width: 1440px) {
  .question-text,
  .answer-text {
    font-size: 15px;
  }

  .ai-icon {
    font-size: 40px;
  }
  .user-icon {
    font-size: 20px;
  }

  .timer {
    font-size: 22px;
  }

  .next-question-timer {
    font-size: 20px;
  }

  .stop-recording-button,
  .start-recording-button {
    font-size: 20px;
    padding: 20px 40px;
  }

  .thank-you-message {
    font-size: 24px;
  }
}
